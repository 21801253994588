import React from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Seo from "../components/seo"

const Page404 = () => {

  return (
    <div>
      <Seo title={"GrowKarma | You are lost :("} />
      <Navbar />
      <div className="has-background-dark py-6">
        <section className="section my-6">
          <div className="container">
            <div className="columns">
              <div className="column is-5">
                <div className="content">
                  <p className="has-text-white has-text-weight-bold is-size-3">Oops! Apparently we did a terrible job and got you lost. Our sincere appologies!</p>
                  <p className="has-text-white">If you want to feel better, you can mail me, Radu, the designer and creator of this website and get the anger off your chest. Or you can ask about the thing you were looking for. Whatever I can do to make this situation better will make me happy.</p>
                  <div className="buttons">
                    <a href="mailto:radu@winspire.co" className="button is-warning" target="_blank" rel="noreferrer">
                      Send Radu an email
                  </a>

                    <a href="/" className="button is-success">
                      Just take me to the homepage
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>

  )
}

export default Page404;

